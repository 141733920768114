exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nase-vina-js": () => import("./../../../src/pages/nase-vina.js" /* webpackChunkName: "component---src-pages-nase-vina-js" */),
  "component---src-pages-penzion-js": () => import("./../../../src/pages/penzion.js" /* webpackChunkName: "component---src-pages-penzion-js" */),
  "component---src-pages-poptavka-js": () => import("./../../../src/pages/poptavka.js" /* webpackChunkName: "component---src-pages-poptavka-js" */),
  "component---src-pages-prazdna-js": () => import("./../../../src/pages/prazdna.js" /* webpackChunkName: "component---src-pages-prazdna-js" */),
  "component---src-pages-sklep-js": () => import("./../../../src/pages/sklep.js" /* webpackChunkName: "component---src-pages-sklep-js" */)
}

